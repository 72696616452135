import { Tag } from './js/utils/tag.js';

window.googleApiKey = "AIzaSyB9d5Gnuu7J3pfuxOSvNA4TIOTEnGHbTwU";

// *** Utils ***
import "./js/utils/translate.js";
import "./js/utils/utils.js";
import "./js/editor-front.js";
import "./js/utils/loader.js";
import "./js/app.js";
import "./js/cookies.js";
import "./js/utils/css.js";
import "./js/utils/modal.js";

// *** Tools ***
import "./swiper/js/swiper.js";
import "./js/tools/scrolleasing.js";
import "./js/tools/scrolleasing.js";
import "./js/tools/typinganimation.js";
import "./js/tools/vc-animation.js";
import "./js/tools/dynamic-components.js";
import "./js/tools/aos.js";
import "./js/tools/dotlottie-player.js";
import "./js/tools/vc-map.js";
import "./js/tools/vc-carousel.js";
import "./js/tools/vc-counter.js";

import "./js/tools/nav.js";
import "./js/watcher.js";
// import "./allsenses/js/fullpagescroll.js"

addScript(`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&callback=mapInit`);


// Cookies consent - load cookies after accept
window.addEventListener("cookie.accepted", function(event) {

    if (checkCookie("analyticalCookies")) {
        // Load analytical cookies
        console.log("Load analytical cookies");
        addScript("/scripts/js/tools/analytics/bowser.js", "text/javascript", function() {
            addScript("/scripts/js/tools/analytics/analytics.js", "module")
        })
    }

    if (checkCookie("marketingCookies")) {
        // Load marketing cookies
        console.log("Load marketing cookies");

        let promoModal = new Modal({disableClose: false, stickBottom: false, classList: ['p-6', 'p-8-md']});

        promoModal.append(new Tag({
            tag: "h5",
            classList: ["cookie-title", "font-weight-semibold", "mb-4"],
            text: "Pierwszy trening wprowadzający EMS -50% 💪",
        }));

        promoModal.append(new Tag({
            tag: "p",
            classList: ["mb-4"],
            text: "Nie czekaj! Dołącz do nas już teraz i odkryj moc EMS. Zmień swoje życie i dołącz do społeczności treningowych EMS!",
        }));

        let buttonGrid = new Tag({
            tag: "div",
            classList: ["grid-x", "container", "mt-4"],
            style: {
                "--gap": "12px",
            }
        });

        promoModal.append(buttonGrid);

        buttonGrid.append(new Tag({
            tag: "a",
            classList: ["cell", "md-6", "sm-12", "button", "error", "rounded", "text-center"],
            text: "Zapisz się",
            attr: {
                href: "tel:048537797723",
            },
            events: {
                click: function() {
                    localStorage.setItem("promoModal", "true");
                    setTimeout(function() {
                        promoModal.hide();
                    }, 1000);
                }
            }
        }));

        buttonGrid.append(new Tag({
            tag: "a",
            classList: ["cell", "md-6", "sm-12", "button", "gray", "outline", "rounded", "text-center"],
            text: "Zobacz ofertę",
            events: {
                click: function() {
                    promoModal.hide();
                }
            }
        }));


        localStorage.getItem("promoModal") == null ? setTimeout(function() {
            promoModal.show();
        }, 2500) : null;
    }

});

window.getComputedStyleByGlobalCompositeOperatorWithExclude = function(key, value) {
    return getComputedStyle(document.body)[key].split(value).join("");
};

setTimeout(function(){
	$(window).trigger("load");
},100);