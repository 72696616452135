// import {animate} from "./animate.js";

import { VcAnimation } from './tools/vc-animation.js';

document.querySelectorAll("dotlottie-player").forEach(function(player) {
    player.addEventListener("ready", function(e) {

        // If player has class include "text-"
        if (player.classList.toString().includes("text-")) {
            let frameEvent = player.addEventListener("frame", function(e) {
                player.shadowRoot.querySelectorAll("path").forEach(function(path) {
                    if (path.getAttribute("fill")) {
                        path.style.fill = "currentColor";
                    }

                    if (path.getAttribute("stroke")) {
                        path.style.stroke = "currentColor";
                    }
                });
            });

            player.addEventListener("loop", function(e) {
                player.removeEventListener("frame", frameEvent);
            });
        }

    });
});

$(window).on("load", function() {
    $("#nav-icon").on("click", function() {
        $("#main-menu").toggleClass("active");
        $("#nav-icon").toggleClass("active");
    });
},{once:true});

document.querySelectorAll("img[data-src]").forEach((e) => {
    // Add parent class skeleton
    e.parentElement.classList.add("skeleton");
});


$(window).on("load", function() {
	loadAllMain();

    let mouseX = 0;
    let mouseY = 0;

    let ballX = 0;
    let ballY = 0;

    let speed = 0.2;  //how fast ball catches up to mouse pointer;

    // Create dot cursor
    let dotCursor = document.createElement("div");
    dotCursor.classList.add("dot-cursor-container");
    document.body.appendChild(dotCursor);

    // Move dot cursor
    document.addEventListener("mousemove", function(e) {
        mouseX = e.clientX;
        mouseY = e.clientY;
    });

    $(".cursor-dot").on("mouseenter", function() {
        dotCursor.classList.add("active");
    });

    $(".cursor-dot").on("mouseleave", function() {
        dotCursor.classList.remove("active");
    });

    $(".cursor-dot picture").on("mouseenter", function() {
        dotCursor.classList.add("picture");
    });

    $(".cursor-dot picture").on("mouseleave", function() {
        dotCursor.classList.remove("picture");
    });

    function animateDot() {
        let distX = mouseX - ballX;
        let distY = mouseY - ballY;
        ballX = ballX + (distX * speed);
        ballY = ballY + (distY * speed);
        dotCursor.style.left = ballX + 'px';
        dotCursor.style.top = ballY + 'px';
        requestAnimationFrame(animateDot)
    }

    animateDot();

    function stickyHeader() {
        let scroll = window.scrollY;

        if (scroll > window.innerHeight / 2) {
            $("header").addClass("pre-sticky");
        } else {
            $("header").removeClass("pre-sticky");
        }

        if (scroll > window.innerHeight) {
            $("header").addClass("sticky");
        } else {
            $("header").removeClass("sticky");
        }
    }

    $(window).on("scroll", function() {
        stickyHeader();
    });
    stickyHeader();


    $("#panel-bar-hover").on("mouseenter", function() {
        $(".panel-bar").addClass("active");
    });

    $(".panel-bar").on("mouseleave", function(e) {
        $(".panel-bar").removeClass("active");
    });

    scrollTo(0, window.scrollY);

    $("a[data-smooth-scroll]").on("click", function(e) {
        // Smooth scroll to element
        const href = $(this).attr("href");
        console.log(href);
        const target = $(href);

        if (target.length > 0) {
            e.preventDefault();
            $(window).scrollTo(0, target.get(0).getBoundingClientRect().top + window.scrollY - 50, 500);
        }
    });

    $("textarea[value]").each(function() {
        $(this).val($(this).attr("value"));
    });

    // Modal
    $("[data-modal]").on("click", function() {
        const modal = $(`#${$(this).attr("data-modal")}`);
        modal.toggleClass("active");

        if (modal.hasClass("active")) {
            $(".modal-background").addClass("active");
        } else {
            $(".modal-background").removeClass("active");
        }
    });

    $("[data-modal-close]").on("click", function() {
        const modal = $(this).closest(".modal");
        modal.removeClass("active");
        $(".modal-background").removeClass("active");
    });

    const modalBackground = document.createElement("div");
    modalBackground.classList.add("modal-background");
    document.body.appendChild(modalBackground);

    $(modalBackground).on("click", function() {
        $(".modal.active").each(function() {
            if (!$(this).hasClass("disable-close")) {
                $(this).removeClass("active");
            }
        });

        if ($(".modal.active").length == 0) {
            $(this).removeClass("active");
        }
    });


    // Dropdown
    $(".dropdown").each(function() {
        const content = $(this).find(".content");

        $(this).on("click", function() {
            $(this).toggleClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("blur", function() {
            $(this).removeClass("active");
            content.css("max-height", null);
        });

        $(this).on("focus", function() {
            $(this).addClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("mouseenter", function() {
            $(this).addClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("mouseleave", function() {
            $(this).removeClass("active");
            content.css("max-height", null);
        });
    });


    // Accordion
    $(".accordion > .title").on("click", function() {
        $(this).parent().toggleClass("active");
        $(this).parent().removeClass("pre-active");
        const item = $(this).next();
        if ($(this).parent().hasClass("active")) {
            item.css("max-height", item.prop("scrollHeight") + "px");
        } else {
            item.css("max-height", `0px`);
        }
    });


    // Tooltip
    const tooltips = document.createElement("div");
    tooltips.classList.add("tooltip");
    document.body.appendChild(tooltips);

    $("[data-tooltip]").on("mouseenter", function() {
        tooltips.innerHTML = $(this).attr("title");
        tooltips.addClass("active");
        const type = $(this).attr("data-tooltip") || "bottom";
        if (type) {
            let rect = this.getBoundingClientRect();
            let top = rect.top + window.scrollY;
            let left = rect.left + window.scrollX;
            let width = rect.width;
            let height = rect.height;

            let tooltipWidth = tooltips.offsetWidth;
            let tooltipHeight = tooltips.offsetHeight;

            let offset = 10;

            if (type === "top") {
                let x = left + width / 2 - tooltipWidth / 2;
                let y = top - tooltipHeight - offset;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "bottom") {
                let x = left + width / 2 - tooltipWidth / 2;
                let y = top + height + offset;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "left") {
                let x = left - tooltipWidth - offset;
                let y = top + height / 2 - tooltipHeight / 2;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "right") {
                let x = left + width + offset;
                let y = top + height / 2 - tooltipHeight / 2;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            }

            tooltips.classList.add(type);
        }
    });

    $("[data-tooltip]").on("mouseleave", function() {
        tooltips.removeClass("active");
        tooltips.removeClass("top");
        tooltips.removeClass("bottom");
        tooltips.removeClass("left");
        tooltips.removeClass("right");
        tooltips.style = "";
    });

    // Add async scripts
    addScript("https://www.google.com/recaptcha/api.js");

    $(".play-video").on("click", function() {
        let videoModal = new Modal({disableClose: false, stickBottom: false, classList: ['p-0']});

        videoModal.append(new Tag({
            tag: "video",
            classList: ["w-100", "aspect-16-9"],
            attr: {
                src: "/public/files/ems.mp4",
                controls: true,
            },
            style: {
                "width": "100%",
                "max-height": "90vh",
                "aspect-ratio": "9/16"
            }
        }));

        videoModal.show();


    })

},{once:true});

window.vcanimations = [];
const loadAllMain = window.loadAllMain = function() {

    // Load VcAnimation
    $("[vcanimation]").each(function() {
        if ($(this).attr("vcanimation") == "loaded") return;

        const aniData = $(this).attr("vcanimation");
        const json = JSON.parse(aniData);

        for (const jsonElement of json) {
            const animation = new VcAnimation(this, jsonElement);
            window.vcanimations.push(animation);
        }
        $(this).attr("vcanimation", "loaded");
    });

    $("iframe[data-src]").each(async function() {
        let obj = $(this);
        if (obj.isInViewport(1000)) {
            obj.attr("src", obj.attr("data-src"));
            obj.removeData("src");
        }
    });

    $("img[data-src]").each(async function() {
        let obj = $(this);
        if (obj.isInViewport(1000) || obj.attr("forceload")) {
            if (obj.attr("data-src").endsWith(".svg")) {
                if (obj.attr("data-src-loading") == "true") return;
                obj.attr("data-src-loading", true);

                fetch(obj.attr("data-src")).then(response => response.text()).then((response) => {

                    if (response.includes("<!DOCTYPE html>")) {
                        if (obj.attr("data-src").includes("/icons/file")) {
                            obj.attr("data-src-loading", false);
                            obj.attr("data-src", '/images/icons/files/file.svg');
                            loadAllMain();
                        }
                        return;
                    }

                    // If parent of image is picture
                    let replaceObj = obj;
                    if (obj.parent().is("picture")) {
                        replaceObj = obj.parent();
                    }

                    // Replace outerHTML of image with SVG, and copy all attributes
                    const classes = replaceObj.attr("class");
                    const id = replaceObj.attr("id");

                    // Change response to element
                    const parser = new DOMParser();
                    const svg = parser.parseFromString(response, "text/html").querySelector("svg");

                    // Copy attributes
                    if (classes) svg.setAttribute("class", classes);
                    if (id) svg.setAttribute("id", id);

                    // Replace outerHTML
                    replaceObj.outerHTML(svg.outerHTML);

                }).catch(error => function() {
                    console.log("Error loading SVG", error);
                });

            } else {
                /*                obj.attr("src", obj.data("src"));
                                obj.on("error", function () {
                                    obj.attr("src", "/th/sm/UNKNOWN");
                                });
                                obj.removeData("src");

                                obj.on("load", function () {
                                    let preloader = $(this).findParent(".preloader");
                                    if (preloader && preloader.length > 0 && preloader[0]) {
                                        preloader.removeClass("loading").removeClass("preloader")
                                    }
                                });*/

                const url = obj.data("src");
                obj.removeData("src");

                obj.on("load", function() {
                    obj.parent().removeClass("skeleton");
                });
                obj.attr("src", url);

            }
        }
    });

    $("[data-bg]").each(async function() {
        const obj = $(this);
        const src = obj.attr("data-bg");
        if (obj.isInViewport(1000)) {
            obj.attr("style", `background-image:url(${src})`);
        }
    });

    $("source[data-srcset]").each(function() {
        let obj = $(this);
        if (obj.isInViewport(1000)) {
            obj.attr("srcset", obj.data("srcset"));
            obj.on("error", function() {
                obj.attr("srcset", "/th/sm/UNKNOWN");
            });
            obj.removeData("srcset");
        }
    });

    interchange();
};

$("#cookie .close-cookie").on("pointerup", function() {
    $.cookie("COOKIE", true, 90);
    const cookie = $.cookie("COOKIE");
    if (cookie != "") {
        $("#cookie").addClass("hide");
    }
});

const cookie = $.cookie("COOKIE");
if (cookie != "") {
    $("#cookie").addClass("hide");
}

window.mapInit = function() {
    // data-mapadvanced

    if (typeof GoogleMap === 'undefined') {
        setTimeout(mapInit, 50)
        return;
    }

    $("[data-mapadvanced]").each(function() {
        let map = new GoogleMap(this, 51.077926, 15.799970, 9)
        let data = $(this).attr("data-mapadvanced");
        data = JSON.parse(data);
        console.log(data);

        if (data.address) map.addMarkerByAddress(data.address, "/static/pin.svg");
        if (data.centerAddress) map.setCenterByAddress(data.centerAddress);

        if (data.pins) {
            for (const pin of data.pins) {
                map.addMarkerByAddress(pin.address, pin.pin);
            }
        }

        map.initMap();

        if (data.dragable) map.setDraggable(!data.dragable);
        if (data.scrollwheel) map.setScrollwheel(!data.scrollwheel);
        if (data.zoomControl) map.setZoomControl(!data.zoomControl);
        if (data.disableDoubleClickZoom) map.setDisableDoubleClickZoom(!data.disableDoubleClickZoom);
        if (data.mapTypeControl) map.setMapTypeControl(!data.mapTypeControl);
        if (data.scaleControl) map.setScaleControl(!data.scaleControl);
        if (data.streetViewControl) map.setStreetViewControl(!data.streetViewControl);
        if (data.rotateControl) map.setRotateControl(!data.rotateControl);
        if (data.fullscreenControl) map.setFullscreenControl(!data.fullscreenControl);
        if (data.style) map.changeStyle(JSON.parse(data.style));

    });
}


$(window).on("scroll", function() {
	loadAllMain();
});
